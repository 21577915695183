
































import {defineComponent} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import ChemicalNotationInput from '@/chemical-equations/components/ChemicalNotationInput.vue';

export default defineComponent({
  name: 'OleMissBuffersPostlabQuestion1',
  components: {ChemicalNotationInput},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        balancedEquationA: null,
        balancedEquationB: null,
      },
    };
  },
});
